import React from "react";
import backgroundImage from "./assets/Background.png";
import { Grid } from "@mui/material";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import "./index.scss";
import image1 from "./review-assets/Alpesh Patel.jpg";
import image2 from "./review-assets/Vinodbhai Patel.jpg";
import image3 from "./review-assets/Prashant.jpg";
import image4 from "./review-assets/Ramila ben.jpg";
import image5 from "./review-assets/Natvar Parmar.jpg";
import image6 from "./review-assets/Tofik masi.jpg";
import image7 from "./review-assets/Satish Patel.jpg";
import image8 from "./review-assets/shantibhai1.png";
import image9 from "./review-assets/Joita Prajapati.jpg";
import image10 from "./review-assets/Yogesh Raval.jpg";
import image11 from "./review-assets/roshanali.png";
import image12 from "./review-assets/chaturdan.png";

const Testimonial = () => {
  const SwitchComponent = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#1D5F00",
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "#D8E5D9",
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#1D5F00",
    },
    "& .MuiSwitch-switchBase + .MuiSwitch-track": {
      backgroundColor: "#1D5F00",
      color: "#1D5F00",
      opacity: "0.5",
    },
    " .MuiSwitch-thumb": {
      backgroundColor: "#1D5F00",
    },
  }));

  let reviewData = [
    {
      guj_comment:
        "બુલ કંપની નો તોલમાપ અને પેમેન્ટ કરવાની પધ્ધતિ ખૂબ સરસ છે તેમજ કંપની ના માણસો નો ખેડૂત પ્રત્યે નો વરતાવ ખુબ સંતોષકારક છે. આપ ના કામ થી ખૂબ આનંદ થયો આભાર સહ, ડાહ્યાભાઇ ચૌધરી, કંપનીના સુપરવાઈઝર, કંપની ની નામના વધારે તેવુ કામ છે ધન્યવાદ",
      eng_comment:
        "The weighing system and payment process of Bull Company is very good. Also the behavior of the supervisors towards the farmers is very pleasing. I'm really happy from the work you're doing. The supervisor of the company, Dahyabhai Chaudhari, is dedicated to the service of farmers. With Thanks.",
      guj_address: "પાણવી, રાધનપુર, પાટણ",
      eng_address: "Panavi, Radhanpur, Patan",
      guj_name: "ચતુરદાન ગઢવી",
      eng_name: "Chaturdan Gadhvi",
      image_url: image12,
    },
    {
      guj_comment:
        "મેં બુલ કંપનીમાં ૧૧૦ બોરી દિવેલાનું વેચાણ કરેલ છે. જેનું પેમેન્ટ ફક્ત ૧૨ કલાકમાં મને ચૂકવી દેવામાં આવ્યું અને તોલ પણ સાચો છે અને આ કંપનીની સર્વિસ ખૂબ જ સારી છે.",
      eng_comment:
        "I've sold 110 bags of castor through Bull Company. The payment was credited in my account within 12 hours and the weighing practices are also satisfactory. The services of the company are really good.",
      guj_address: "પટેલના મુવાડા, બાયડ",
      eng_address: "Patelna Muvada, Bayad",
      guj_name: "વિનોદભાઈ પટેલ",
      eng_name: "Vinodbhai Patel",
      image_url: image2,
    },
    {
      guj_comment:
        "તારીખ 10/08/2024 ના રોજ મેં દિવેલા વેચાણ અર્થે બુલ કંપનીમાં આપેલ, જેમાં મને તોલમાપની અને બજારભાવ ની બહુ સરસ કામગીરી જોવા મળી હતી તેમજ તેમના કર્મચારી બહુ સચોટ રીતે અને કાળજીપૂર્વક કામગીરી બજાવતા હતા. અન્ય ખેડૂત મિત્રોએ પણ ત્યાં વેચાણ કરવું યોગ્ય છે.",
      eng_comment:
        "On 10 August 2024 I contacted Bull Company to sell castor. Here I witnessed really good weighing practice and trade on live market prices. The staff was carefull and did accurate work. I also advise other farmers to sell through Bull Company.",
      guj_address: "દખનેશ્વર, બાયડ",
      eng_address: "Dakhneshwar, Bayad",
      guj_name: "પ્રશાંતકુમાર નટવરલાલ પટેલ",
      eng_name: "Prashantkumar Natvarlal Patel",
      image_url: image3,
    },
    {
      guj_comment:
        "મારા પતિના દુઃખદ અવસાન બાદ પાંચ બાળકોના ઉછેર, ખેતી અને ઢોર ઢાંખરનું બીડું મે ઉપાડ્યું હતું. એરંડા વેચવા માટે આજ રોજ મેં બુલ કંપનીનો સંપર્ક કર્યો. માર્કેટનો મને એટલો તો અનુભવ છે કે કોઈ માણસ ખેડૂત સામે જોતું નથી. પણ બુલ કંપનીના અશ્વિનભાઈએ મારી વાત જાણીને મને મહિના પછી પેમેન્ટ લેવાનો વિકલ્પ બતાવ્યો કે જેથી ₹૧૦ વધારે મળે અને ઇસેદુ મિલના પ્રગતિ પ્રોજેક્ટમાં અમારું નામ હતું જ. જ્યારે મોટા મોટા બજારો ૧૨૯૨-૯૮ નો ભાવ બતાવે છે મને બુલ કંપની દ્વારા ₹૧૩૨૫ નો ભાવ મળ્યો! તાત્કાલિક પૈસાની જરૂર ન હોવાથી મને મહિના પછીનું પેમેન્ટ યોગ્ય લાગ્યું, તથા બુલ કંપની ની પારદર્શિતા અને વિશ્વાસ તો ખરા જ. અપેક્ષાથી વધુ સહકાર બદલ હું બુલ કંપનીનો આભાર માનું છું.",
      eng_comment:
        "After the unfateful demise of my husband, I took up the resposibilty of raising five children, farming and our animals. I contacted Bull Company today to sell castor. I've enough experience of the market to know that nobody cares for the farmer. But Ashwinbhai from Bull Company listened and understood my situation, and suggested that I should opt for the 'payment after a month' so that I can get a 10 rupees raise on the rate. We were also registered under the Pragati project oh Ihsedu. Thus, when big markets were quoting 1292-98 rate, I was offered the rate of 1325. I chose the delayed payment as I had trust in the tranparency of the company. I thank Bull comapny for the unexpected supporte they have showed me.",
      guj_address: "કાબસો, ઇડર, સાબરકાંઠા",
      eng_address: "Kabso, Idar, Sabarkantha",
      guj_name: "રમીલાબેન ગજેન્દ્રભાઈ પરમાર",
      eng_name: "Ramilaben Gajendrabhai Parmar",
      image_url: image4,
    },
    {
      guj_comment:
        "બુલ કંપની સચોટ માહિતી આપનાર હોવાથી મિત્રો માટે આશીર્વાદ રૂપ છે. બુલ કંપનીની કાર્ય પદ્ધતિ પારદર્શક હોવાથી ખેડૂતોને છેતરવાનો ભય રહેતો નથી. કંપનીના કર્મચારીઓનું યોગ્ય વર્તન અને ભાવ નક્કી કર્યા બાદ ટાઈમ પર માલ ભરી જવાના કારણે ખૂબ જ વિશ્વાસપાત્ર બની છે. દરરોજના માર્કેટ ભાવ પ્રમાણે અને ગુણવત્તા પ્રમાણે ખરીદી કરતી હોવાથી બુલ કંપની ખેડૂતો માટે નંબર વન બની રહી છે.",
      eng_comment:
        "Bull company provides us with accurate information of trade and is like a boon for farmers. The practices of the company is transparent. Bull company has since become quite trustworthy due to the good behaviour of the staff and timely purchase from home. Bull Company buys according to the daily rate of market and offers price according to the quality. The Bull company has become the Number One company for farmers.",
      guj_address: "શંકરપુરા કંપા, બાયડ",
      eng_address: "Shankarpura Kampa, Bayad",
      guj_name: "અલ્પેશભાઈ પટેલ",
      eng_name: "Alpeshbhai Patel",
      image_url: image1,
    },
    // {
    //   guj_comment:
    //     "બુલ કંપનીમાં મે દિવેલા વેચેલ અને તેના અનુભવમાં મને સારી એવી સર્વિસ મળેલ છે. ભાવ પણ માર્કેટ યાર્ડ કરતા સારા આપેલ છે. ત્યાંના કર્મચારીઓનો વ્યવહાર સારો છે. પેમેન્ટ ટાઇમસર મળી જાય છે અને ઘરે બેઠા માલ ભરાય છે. તોલ પણ ચોખ્ખો છે. કોઈ પણ પ્રકારની માહિતી સારી રીતે કર્મચારીઓ આપે છે. બુલ કંપની પાકનું વેચાણ કરવા માટે સારામાં સારી કંપની છે એવો મારો જાત અનુભવ છે.",
    //   eng_comment:
    //     "I've sold Castor to Bull Company and have experienced a good service. The rate was even higher than market yard rates. The staff is good in nature. The material was loaded from home and the payment was credited on time. The weighting is neat. All of my queries were addressed satisfactorily by the staff. By my own experience, I can say that the Bull Company is the best way to sell crop.",
    //   guj_address: "ભુખેલ, બાયડ",
    //   eng_address: "Bhukhel, Bayad",
    //   guj_name: "નટવરભાઈ ધુળાભાઈ પરમાર",
    //   eng_name: "Natvarbhai Dhulbhai Parmar",
    //   image_url: image5,
    // },
    // {
    //   guj_comment:
    //     "હું ખેતીનો વ્યવસાય કરું છું અને વધારે પ્રમાણમાં એરંડાનું વાવેતર કરું છું. છેલ્લા 3 વર્ષથી બુલ કંપનીના દેશોતર સેન્ટરમાં એરંડાનું વેચાણ કરું છું. સદર બુલ કંપનીની ખરીદ પદ્ધતિ, તોલમાપ, પેમેન્ટ ખૂબ જ સંતોષ કારક છે. તેમજ કંપનીના કર્મચારી મિત્રો પણ માલ લઈને આવનાર ખેડૂતો સાથે સારો વર્તાવ અને પૂરી ઈમાનદારીથી પોતાની ફરજ બજાવે છે. સાથે દરરોજના બજારભાવ બાબતમાં પણ ખેડૂતોને સજાગ કરે છે. જેથી બુલ કંપની ખેડૂતો માટે આશીર્વાદ રૂપ છે.",
    //   eng_comment:
    //     "I'm a farmer by occupation and sow castor. I've been selling at the Deshotar center of Bull company since 3 years. Company's processes of purchase, weighing and payment are all very good. Also the supervisors of Bull company ",
    //   guj_address: "કેશરપુરા, સાબરકાંઠા",
    //   eng_address: "Kesharpura, Sabarkantha",
    //   guj_name: "તોફિકભાઈ એ. મસી",
    //   eng_name: "Tofikbhai A. Masi",
    //   image_url: image6,
    // },
    {
      guj_comment: `કર્મચારીઓનો વ્યવહાર સારો છે.
                    પેમેન્ટ કન્ડીશન સારી છે.
                    માપ તોલ કંપ્લેટ કરે છે. 
                    ઘરબેઠા ભરી જવામાં કોઈ તકલીફ નથી.`,
      eng_comment:
        "The field staff is very good. Payment condition is well explained at trade, and payment is always on time. Never had complaints about weighing. And the Buy from home facility is splendid.",
      guj_address: "કુબધરોલ કંપા, વડાલી, સાબરકાંઠા",
      eng_address: "Kubadharol Kapma, Vadali, Sabarakantha",
      guj_name: "સતિષભાઈ જેઠાભાઈ પટેલ",
      eng_name: "Satishbhai Jethabhai Patel",
      image_url: image7,
    },
    {
      guj_comment: `મેં બુલ કંપની સાથે 3 ગાડી, ૮૩૩ બોરી મગફળીનો વ્યાપાર કર્યો છે. બજારમાં સારામાં સારો ભાવ મને ઘરે બેઠા મળી ગયો. સોદો કર્યાના બીજા જ દિવસે માર્કેટમાં ₹૧૦૦ નો કડાકો બોલ્યો અને મને ચિંતા હતી કે ક્યાંક બીજા વ્યાપારીઓની જેમ બુલ સાથે પણ ભાવની રકજક થશે. પણ ના! આવતા 3 દિવસોમાં મારા ઘરેથી ગાડીઓ ભરાઈ અને મને એજ ભાવ મળ્યો! ગાડી ઘર છોડે એ પેહલા તો મારા ખાતામાં પેમેન્ટ આવી ગયું હતું.
                    મારો સમય બચ્યો અને સારામાં સારો ભાવ મળ્યો એનો મને સંતોષ છે. ખેડૂતોને આટલી સરસ સર્વિસ પૂરી પાડવા બદલ હું બુલ કંપનીનો આભારી છું!`,
      eng_comment:
        "I've sold 833 bags of Groundnut to Bull. I got the best price right at my home. A day after the rate was confirmed and I agreed on terms, the market crashed. I was worried if trade would be cancelled just like with traders. But No! In the next 3 days, the trucks were loaded from my home and I got the same rate as agreed. The payment was credited to my account before the trucks even left my home. A lot of my time was saved and got the best price. I'm thankful to Bull Company for providing such splendid service to farmers.",
      guj_address: "હરીપુરા કંપા, બાયડ, અરવલ્લી",
      eng_address: "Haripura Kampa, Bayad, Aravlli",
      guj_name: "શાંતિભાઈ મગનભાઈ પટેલ",
      eng_name: "Shantibhai Maganbhai Patel",
      image_url: image8,
    },
    {
      guj_comment:
        "બુલ કંપનીમાં અમોએ દિવેલા ભરાયા હતા. અમોને ભાવમાં ગંજ કરતા દસ રૂપિયાનો ભાવ વધારો મળ્યો. પેમેન્ટ કહ્યા પ્રમાણે ખાતામાં જમા થઈ ગયું ને તોલમાપમાં પણ બરાબર લાગ્યું ને સેન્ટર પર હાજર માણસનો સ્વભાવ પણ સારો છે. બુલ કંપની ઉત્તરોઉત્તર પ્રગતિ કરે અને ખેડૂતોને લાભ આપે એવી અમારી શુભેચ્છા. જય હિન્દ.",
      eng_comment:
        "I sold castor to Bull Company. I got Rs.10 more that the APMC rate. Payment was credited in my account as told. My well wishes to the company and I hope it prospers more and more. Jay Hind!",
      guj_address: "નાના કોઠાસણા, સતલાસણા",
      eng_address: "Nana Kothasana, Satlasana",
      guj_name: "જોઇતાભાઈ ડી. પ્રજાપતિ",
      eng_name: "Joitabhai D. Prajapati",
      image_url: image9,
    },
    {
      guj_comment:
        "હું છેલ્લા ત્રણ વર્ષથી બુલ કંપનીમાં માલ ભરાવું છું. બુલ કંપનીનું કામકાજ બહુ જ સારું છે. બુલ કંપનીના આવવાથી આસપાસના ખેડૂતોમાં સુધારો આવેલ છે. અમને રોજેરોજ ભાવ ફોનમાં આવવાથી અમારે માલ ક્યારે વેચવો તેની જાણકારી ફોનના માધ્યમથી સમયસર મળી રહે છે. બુલ કંપનીમાં પેમન્ટનું, તોલનું કામકાજ સારું છે. પેમન્ટ સમયસર ખાતામાં જમા થાય છે જે સંતોષકારક છે. સેન્ટરના સુપરવાઈઝર ખેડૂતને સંતોષકારક જવાબ આપે છે અને વાતચીતની રીત બહુ સારી છે.",
      eng_comment:
        "I've been selling castor to Bull since last 3 years. Work of the company is very good. The lives farmers around has improved after Bull company set up a center here. We receive daily prices on our phones and can decide when to sell our crop. The payment condition is satisfactory. We're explained everything in details by centre's supervisor.",
      guj_address: "દેશોતર, ઇડર",
      eng_address: "Deshotar, Idar",
      guj_name: "યોગેશકુમાર મગનલાલ રાવળ",
      eng_name: "Yogeshkumar Maganlal Raval",
      image_url: image10,
    },
    {
      guj_comment:
        "બુલ કંપની એક સંતોષકારક અને વિશ્વાસપાત્ર કંપની છે. તે ખેડૂતનો મિત્ર અને હિત સમાન છે. બુલ કંપનીમાં કાર્યકર્તાઓનો વ્યવહાર બઉ સરસ છે. માપતોલમાં પણ ચોકસાઈ રાખે છે. ઘરે બેઠા માલ ભરવામાં પણ સહયોગ આવે છે. માલ વેચાણ બાદ પેમેન્ટ ખૂબ જ ઝડપથી મળી જાય છે.",
      eng_comment:
        "Bull company is a trustworthy company. They are like a friend and well wisher for farmers. The staff is well trained of good nature. They take complete care of quality and weight. They are fully compliant for buying from home. The payment is fast after complition of trade.",
      guj_address: "ઝહિરપુરા, ઇડર",
      eng_address: "Zahirpura, Idar",
      guj_name: "રોશનઅલી ગુલામઅલી નાગલપરા",
      eng_name: "Roshanali Gulamali Nagalpara",
      image_url: image11,
    },
  ];

  let trimmedString = (Value) => {
    return Value.length > 190 ? Value.substring(0, 190) + "..." : Value;
  };
  const [isEnglishLan, setIsenglish] = React.useState(true);

  return (
    <Grid
      container
      style={{ margin: "0px", padding: "0px", paddingBottom: "20px" }}
    >
      <Grid item sm={12} style={{ position: "relative" }}>
        <img
          src={backgroundImage}
          style={{
            width: "100%",
          }}
        />
        <div
          style={{
            position: "absolute",
            top: "0px",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            flexDirection: "column",
            background:
              "linear-gradient(360deg, rgba(0, 0, 0, 0) 40%, #000000 100%)",
            paddingTop: "5%",
          }}
        >
          <span className="head-title">Hear from</span>
          <span
            className="head-title"
            style={{
              color: "#1D5F00",
            }}
          >
            Our Satisfied Farmers
          </span>
          <span className="head-title">Have to Say</span>
        </div>
      </Grid>
      <Grid
        item
        sm={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          padding: "40px 0px 10px 0px",
          width: "100%",
        }}
      >
        <span className="testimonials-title">Testimonials</span>
        <div className="switch-titles">
          ગુજરાતી{" "}
          <SwitchComponent
            checked={isEnglishLan}
            onChange={(e) => setIsenglish(e.target.checked)}
          />{" "}
          English
        </div>
      </Grid>
      <Grid sm={12} item container style={{ padding: "0px 4%" }}>
        {reviewData.map((item) => (
          <Grid
            className="flip-card"
            item
            sm={4}
            md={3}
            style={{ padding: "15px", position: "relative" }}
          >
            <div className="flip-card-inner" style={{}}>
              <div
                className="flip-card-front"
                style={{
                  border: "1px solid #E4E9E5",
                  borderRadius: "24px",
                  padding: "12px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "start",
                }}
              >
                <div
                  className="image-user-container"
                  style={{ width: "100%", height: "280px", overflow: "hidden" }}
                >
                  <img
                    className="image-user"
                    src={item.image_url}
                    style={{
                      width: "100%",
                      borderRadius: "18px",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <span
                  className="hide-class-hover"
                  style={{
                    color: "#000000",
                    fontWeight: "500",
                    fontSize: "20px",
                    paddingTop: "15px",
                  }}
                >
                  {isEnglishLan ? item.eng_name : item.guj_name}
                </span>
                <span
                  className="hide-class-hover"
                  style={{
                    color: "#1D5F00",
                    fontWeight: "500",
                    fontSize: "14px",
                    paddingTop: "2px",
                  }}
                >
                  {isEnglishLan ? item.eng_address : item.guj_address}
                </span>
                <span
                  className="hide-class-hover"
                  style={{
                    color: "#5A5A5A",
                    fontWeight: "500",
                    fontSize: "16px",
                    paddingTop: "10px",
                    paddingBottom: "20px",
                  }}
                >
                  {trimmedString(
                    isEnglishLan ? item.eng_comment : item.guj_comment
                  )}
                </span>
                <div
                  style={{ alignSelf: "center" }}
                  className="hide-class-hover"
                >
                  <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24 6C33.9415 6 42 14.0585 42 24C42 33.9415 33.9415 42 24 42H6L11.0635 36.5147C7.92913 33.2755 6 28.8631 6 24C6 14.0585 14.0585 6 24 6Z"
                      fill="#F2F7F0"
                    />
                    <path
                      d="M31.0434 25.5652C31.0434 27.2931 29.641 28.6956 27.913 28.6956V24.7826H25.5651V20.0869H31.0434V25.5652ZM21.6521 24.7826V25.5652C21.6521 26.4292 21.3015 27.2118 20.7349 27.7784C20.1683 28.345 19.3857 28.6956 18.5217 28.6956V24.7826H16.1738V20.0869H21.6521V24.7826Z"
                      fill="#66BB6A"
                    />
                  </svg>
                </div>
              </div>
              <div className="flip-card-back">
                <span
                  style={{
                    color: "#FFFFFF",
                    fontWeight: "500",
                    fontSize: "16px",
                    paddingTop: "10px",
                    textAlign: "center",
                    wordBreak: "break-word",
                  }}
                >
                  {isEnglishLan ? item.eng_comment : item.guj_comment}
                </span>
              </div>
            </div>
          </Grid>
        ))}
        {/* {[0, 2].map((item) => (
          <Grid item sm={12} md={6} style={{ padding: "15px" }}>
            <div
              style={{
                border: "1px solid #E4E9E5",
                borderRadius: "24px",
                padding: "12px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  width: "40%",
                }}
              >
                <img
                  src={backgroundImage}
                  style={{
                    width: "100%",
                    borderRadius: "18px",
                    minHeight: "200px",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "start",
                  paddingLeft: "12px",
                  width: "60%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <span
                    style={{
                      color: "#000000",
                      fontWeight: "500",
                      fontSize: "20px",
                      paddingTop: "15px",
                    }}
                  >
                    Natvarbhai Dhulbhai Parmar
                  </span>
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 0C27.9415 0 36 8.05852 36 18C36 27.9415 27.9415 36 18 36H0L5.06348 30.5147C1.92913 27.2755 0 22.8631 0 18C0 8.05852 8.05852 0 18 0Z"
                      fill="#F2F7F0"
                    />
                    <path
                      d="M25.0434 19.5652C25.0434 21.2931 23.641 22.6956 21.913 22.6956V18.7826H19.5651V14.0869H25.0434V19.5652ZM15.6521 18.7826V19.5652C15.6521 20.4292 15.3015 21.2118 14.7349 21.7784C14.1683 22.345 13.3857 22.6956 12.5217 22.6956V18.7826H10.1738V14.0869H15.6521V18.7826Z"
                      fill="#66BB6A"
                    />
                  </svg>
                </div>
                <span
                  style={{
                    color: "#1D5F00",
                    fontWeight: "500",
                    fontSize: "14px",
                    paddingTop: "2px",
                  }}
                >
                  Bhukhel, Bayad
                </span>
                <span
                  style={{
                    color: "#5A5A5A",
                    fontWeight: "500",
                    fontSize: "16px",
                    paddingTop: "10px",
                  }}
                >
                  I've sold Castor to Bull Company and have experienced a good
                  service. The rate was even higher than market yard rates. The
                  staff is good in nature. The material was loaded from home and
                  the payment was credited on time. The weighting is neat. All
                  of my queries were addressed satisfactorily by the staff. By
                  my own experience, I can say that the Bull Company is the best
                  way to sell crop.
                </span>
              </div>
            </div>
          </Grid>
        ))}
        {[0, 1, 2, 3].map((item) => (
          <Grid item sm={4} md={3} style={{ padding: "15px" }}>
            <div
              style={{
                border: "1px solid #E4E9E5",
                borderRadius: "24px",
                padding: "12px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
              }}
            >
              <img
                src={backgroundImage}
                style={{
                  width: "100%",
                  borderRadius: "18px",
                  minHeight: "200px",
                }}
              />
              <span
                style={{
                  color: "#000000",
                  fontWeight: "500",
                  fontSize: "20px",
                  paddingTop: "15px",
                }}
              >
                Natvarbhai Dhulbhai Parmar
              </span>
              <span
                style={{
                  color: "#1D5F00",
                  fontWeight: "500",
                  fontSize: "14px",
                  paddingTop: "2px",
                }}
              >
                Bhukhel, Bayad
              </span>
              <span
                style={{
                  color: "#5A5A5A",
                  fontWeight: "500",
                  fontSize: "16px",
                  paddingTop: "10px",
                }}
              >
                I've sold Castor to Bull Company and have experienced a good
                service. The rate was even higher than market yard rates. The
                staff is good in nature. The material was loaded from home and
                the payment was credited on time. The weighting is neat. All of
                my queries were addressed satisfactorily by the staff. By my own
                experience, I can say that the Bull Company is the best way to
                sell crop.
              </span>
              <div style={{ alignSelf: "center" }}>
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24 6C33.9415 6 42 14.0585 42 24C42 33.9415 33.9415 42 24 42H6L11.0635 36.5147C7.92913 33.2755 6 28.8631 6 24C6 14.0585 14.0585 6 24 6Z"
                    fill="#F2F7F0"
                  />
                  <path
                    d="M31.0434 25.5652C31.0434 27.2931 29.641 28.6956 27.913 28.6956V24.7826H25.5651V20.0869H31.0434V25.5652ZM21.6521 24.7826V25.5652C21.6521 26.4292 21.3015 27.2118 20.7349 27.7784C20.1683 28.345 19.3857 28.6956 18.5217 28.6956V24.7826H16.1738V20.0869H21.6521V24.7826Z"
                    fill="#66BB6A"
                  />
                </svg>
              </div>
            </div>
          </Grid>
        ))} */}
      </Grid>
    </Grid>
  );
};

export default Testimonial;
