import { createBrowserRouter } from "react-router-dom";
import HomePage from "../Pages/homepage/HomePage";
import AboutUs from "../Pages/aboutus/AboutUs";
import Layout from "./Layout";
import Contact from "../Pages/contact/Contact";
import Milestones from "../Pages/milestones/Milestones";
import Blogs from "../Pages/blogs/Blogs";
import Testimonial from "../Pages/testimonial/Testimonial";

export const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/aboutus",
        element: <AboutUs />,
      },
      {
        path: "/milestones",
        element: <Milestones />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/blogs",
        element: <Blogs />,
      },
      {
        path: "/testimonial",
        element: <Testimonial />,
      },
    ],
  },
]);
